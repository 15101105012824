export enum E_CONINS_ACTIVE_TAB {
  BURN = 'burn',
  EARN = 'earn',
}

export function useUseBurnCoins() {
  const { t } = useI18n()
  const commonDataStore = useCommonStore()
  const coninsActiveTab = useState<E_CONINS_ACTIVE_TAB>('coinsActiveTab', () => E_CONINS_ACTIVE_TAB.EARN)

  const coinsTitle = computed(() => {
    const { partnerInfo } = commonDataStore.commonData || {}
    return {
      [E_CONINS_ACTIVE_TAB.EARN]: t('key.home.coins.earn.title'),
      [E_CONINS_ACTIVE_TAB.BURN]: t('key.home.coins.burn.title', { partnerName: partnerInfo?.distName || 'xxx', points: 'Coins' }),
    }[coninsActiveTab.value]
  })

  const coinsSubTitle = computed(() => {
    return {
      [E_CONINS_ACTIVE_TAB.EARN]: t('key.home.coins.earn.subTitle'),
      [E_CONINS_ACTIVE_TAB.BURN]: t('key.home.coins.burn.subTitle', { points: 'coins' }),
    }[coninsActiveTab.value]
  })

  return {
    coinsTitle,
    coinsSubTitle,
    coninsActiveTab,
  }
}
